<style scoped>
.width-text-field {
    width: 30%
}

.width-button-responsive {
    width: 25%
}

@media only screen and (max-width:500px) {
    .width-text-field {
        width: 90%
    }

    .width-button-responsive {
        width: 75%,
    }
}
</style>
<template>
    <div class="ma-4">
        <!-- la form pricipale -->
        <v-card class="mt-6" elevation="5">
            <v-toolbar :color="$colors[0]" dark>
                <span class="text-h6 pl-0">Autorisation de Collecte de Données</span>
            </v-toolbar>
            <v-row>
                <loadingComponent @closeDialog="dialog = $event" :loadingProps="loading" :dialogProps="dialog"
                    :messageDialogeProps="messageDialoge" :errorProps="error" />
            </v-row>
            <div style='d-flex' class="ma-0">
                <div class="d-flex  flex-row my-8">
                  
                    <v-form class='width-text-field ma-8 mb-0' ref="formSiren" lazy-validation>
                        <v-text-field outlined :rules="[rules.required, rules.sirenLength, rules.isNumber]"
                            v-model="siren" label="Siren" @input="inseeResponse = false, siretFound = null"
                            @keydown.enter.prevent="getSiretData"></v-text-field>
                        <v-btn @click="getSiretData" depressed elevation="1" large
                            style="color: #fff;background: #FF6600;" class="ma-auto text-white">
                            Rechercher
                        </v-btn>
                    </v-form>
                    <v-form v-if="siretFound == 1 || siretFound == 2" class="width-text-field ma-8 mb-0" ref="formSiret"
                        lazy-validation>
                        <v-combobox ref="siret" :search-input.sync="siret" @input="inseeResponse = false" outlined
                            :rules="[rules.required, rules.siretLength, rules.isNumber]" item-disabled="disable"
                            item-text="name" item-value="name" :items="siretExist" :return-object="false"
                            label="Siret"></v-combobox>
                        <!--<v-text-field
                                    v-if="siretFound == 2"
                                    outlined
                                    :rules="[rules.required, rules.siretLength, rules.isNumber]"
                                    v-model="siret"
                                    label="Siret"
                            ></v-text-field>-->
                        <v-btn v-if="siretFound == 1 || siretFound == 2" @click="rechercheParSiret" depressed
                            elevation="1" style="color: #fff;background: #FF6600;" large class="ma-auto text-white">
                            Valider
                        </v-btn>
                    </v-form>
                </div>
                <v-col class="pa-4" cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-divider v-if="siretInccorect == false && inseeResponse == true"></v-divider>
                </v-col>
                <v-col cols="12" sm="10" md="10" lg="10" xl="12"
                    v-if="siretInccorect == false && inseeResponse == true">
                    <v-form class="ma-4" ref="formUpload" lazy-validation>
                        <v-row>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field outlined :rules="[rules.required]" v-model="raison"
                                    label="Raison sociale"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field outlined :rules="[rules.required]" v-model="adressePostale"
                                    label="Adresse"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field outlined :rules="[rules.required, rules.phoneLength, rules.isNumber]"
                                    v-model="num_tel" label="Numéro de téléphone"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field outlined :rules="[rules.required, rules.isString, rules.hasWhiteSpace]"
                                    v-model="nom" label="Nom"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field outlined :rules="[rules.required, rules.isString, rules.hasWhiteSpace]"
                                    v-model="prenom" label="Prénom"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field outlined :rules="[rules.required, rules.emailRules, rules.hasWhiteSpace]"
                                    v-model="email" label="adresse e-mail"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field outlined :rules="[rules.required, rules.isString, rules.hasWhiteSpace]"
                                    v-model="fonction" label="Poste"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field outlined :rules="[rules.required]" v-model="RCS"
                                    label="RCS"></v-text-field>
                            </v-col>
                            <v-col class="pa-4" cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-divider v-if="siretInccorect == false && inseeResponse == true"></v-divider>
                            </v-col>
                            <v-col cols="10" sm="10" md="10" lg="10" xl="10">
                                <v-radio-group v-model="typeContrat" row>
                                    <v-radio label="GAZ" value="gaz" @click="choisirGaz"></v-radio>
                                    <v-radio label="ELECTRICITÉ" value="elec" @click="choisirElec"></v-radio>
                                    <v-radio label="ELECTRICITÉ & GAZ" value="elec_et_gaz"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-row justify="space-between">
                                    <v-col cols="10" sm="6" md="6" lg="6" xl="4"
                                        v-if="typeContrat === 'gaz' || typeContrat === 'elec_et_gaz'">

                                        <v-text-field outlined :rules="[rules.required, rules.isGazCompteur]"
                                            v-model="num_comp_gaz[0]" label="Numéro de compteur gaz n°1">
                                        </v-text-field>
                                        <v-text-field outlined :rules="[rules.required, rules.isGazCompteur]"
                                            v-for="index in lengthCompteurgaz" :key="index"
                                            v-model="num_comp_gaz[index]"
                                            :label="'Numéro de compteur gaz n°' + (index + 1)">
                                        </v-text-field>
                                        <v-btn depressed elevation="1" large class="responsiveBtn ma-auto text-white"
                                            width="45%" :color="$colors[0]" @click="ajouterCompteurGaz">
                                            Ajouter
                                        </v-btn>
                                        <v-btn v-if="lengthCompteurgaz > 0" depressed elevation="1" large
                                            class="error responsiveBtn ma-auto text-capitalize ml-2" width="45%"
                                            @click="supprimerCompteurGaz">
                                            Supprimer
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="10" sm="6" md="6" lg="6" xl="4"
                                        v-if="typeContrat === 'elec' || typeContrat === 'elec_et_gaz'">
                                        <v-text-field outlined
                                            :rules="[rules.required, rules.compteurElecLength, rules.isNumber]"
                                            v-model="num_comp_elec[0]"
                                            label="Numéro de compteur ELEC n°1"></v-text-field><v-text-field outlined
                                            :rules="[rules.required, rules.compteurElecLength, rules.isNumber]"
                                            v-for="index in lengthCompteurelec" :key="index"
                                            v-model="num_comp_elec[index]"
                                            :label="'Numéro de compteur ELEC n°' + (index + 1)"></v-text-field>
                                        <v-btn depressed elevation="1" large :color="$colors[0]"
                                            class="ma-auto text-white" width="45%" @click="ajouterCompteurElec">
                                            Ajouter
                                        </v-btn>
                                        <v-btn v-if="lengthCompteurelec > 0" depressed elevation="1" large
                                            class="ml-2 error ma-auto text-capitalize" width="45%"
                                            @click="supprimerCompteurElec">
                                            Supprimer
                                        </v-btn>

                                    </v-col>
                                    <v-btn @click="generatePdf" depressed elevation="1" large
                                        style="color: #fff;background: #FF6600;" class="text-white align-self-end">
                                        Examiner le document
                                    </v-btn>
                                    <v-btn v-if="hasGeneratePDF && isSend == 1" @click="enregistrer" depressed
                                        elevation="1" large :color='$colors[0]' class="px-6 text-white align-self-end">
                                        Enregistrer
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </div>
        </v-card>

        <!-- entete du pdf -->
        <div style="position:relative; left:-400%; width:600px;">
            <p id="para" style="margin-left:50px; margin-top:105px;font-size:10px; max-width: 500px;">
                Je soussigné {{ nom }} {{ prenom }} autorise gaz-elec-moinscher.fr a
                acceder aux données mentionnées ci-dessous, pour la société
                {{ raison }} &nbsp;au siren {{ siren }} et
                pour le/les point(s) de mesure suivant:
            </p>
        </div>
    </div>
</template>

<script>
import loadingComponent from "../components/ui/loading-component.vue"
import axios from 'axios'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import dialogeModal from '../components/ui/dialogeModal.vue'

import { degrees, PDFDocument, rgb, StandardFonts, layoutMultilineText } from 'pdf-lib'
import download from 'downloadjs'
import 'jspdf-autotable'

export default {
    name: 'AutorisatonDeCollectionDeDonnees',
    components: {
        dialogeModal,
        loadingComponent
    },
    data() {
        return {
            hasGeneratePDF: false,
            search: "",
            dialog: false,
            error: false,
            loading: false,
            messageDialoge: "L'e-mail a été envoyé avec succès",
            //enedis data
            enedisRecherchePointResult: null,
            missedCompteur: null,
            //radio button data for add compteur
            num_comp_gaz: [],
            lengthCompteurgaz: 0,
            lengthCompteurelec: 0,
            num_comp_elec: [],
            typeContrat: "gaz",
            //data for test exist siret and siren
            siretFound: null,
            siretExist: [],
            //insee response data
            inseeResponse: null,
            //data for pdf 
            RCS: '',
            siret: "",
            siren: "",
            adressePostale: "",
            raison: "",
            nom: "",
            prenom: "",
            raison: "",
            email: "",
            fonction: "",
            codeNaf: "",
            num_tel: "",
            //form data for upload file
            formData: null,
            //l'etat deximination de fichier
            examine_file: true,
            isSend: 0,
            //les rules du formulaire
            rules: {
                required: value => !!value || 'Ce champ est obligatoire.',
                isString: value => !(/\d/.test(value)) || "Ce champ ne peut pas etre composé de chiffres",
                hasWhiteSpace: value => !(/\s/.test(value)) || "Ce champ ne doit pas être constitué d'espace",
                sirenLength: v => !!v == 0 || v?.length == 9 || 'Le siren doit comporter 9 chiffres',
                siretLength: v => !!v == 0 || v?.length == 14 || 'Le siret doit comporter 14 chiffres',
                isNumber: v => v?.match(/^[0-9]+$/) != null || 'Ce champ doit être un nombre',
                compteurElecLength: v => v?.length == 14 || 'Le RAE / PDL est une suite de 14 chiffres',
                isGazCompteur: v => (v?.match(/^(GI)[0-9]{6}$/) || (v?.length == 14 && v.match(/^[0-9]+$/) != null)) || 'Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres',
                phoneLength: v => v?.length == 10 || 'Le numéro de téléphone est une suite de 10 chiffres',
                phoneisNumber: v => v?.match(/^[0-9]+$/) != null || 'Le numéro de téléphone est invalide',
                margeMaxMin: v => (v <= 10 && v >= 2) || 'La marge doit être comprise entre 2 et 10',

                emailRules: v => !!v && /.+@.+\.+.+/.test(v) || 'E-mail invalide',
            },
            verifyLength() {
                return this.siret?.length == 14 || 'Le siret doit comporter 14 chiffres.'
            },
            siretAcceptNumber() {
                return this.siret.match(/^[0-9]+$/) != null || 'Ce champ doit être un nombre'
            },
            siretInccorect: null,
        }
    },
    created() {
        this.typeUser = localStorage.getItem('typeUser')
    },
    methods: {
        // debut dans l'ajoute et suppression d'un compteur dans le formulaire
        ajouterCompteurGaz() {
            this.lengthCompteurgaz += 1
        },
        supprimerCompteurGaz() {
            this.num_comp_gaz.splice(this.lengthCompteurgaz, 1)
            this.lengthCompteurgaz -= 1
        },

        ajouterCompteurElec() {
            this.lengthCompteurelec += 1
        },
        supprimerCompteurElec() {
            this.num_comp_elec.splice(this.lengthCompteurelec, 1)
            this.lengthCompteurelec -= 1
            this.enedisRecherchePointResult = null;
            this.missedCompteur = null;
        },
        // fin dans l'ajoute et suppression d'un compteur dans le formulaire

        //debut test sur le type de choix du compteur GAZ ELEC GAZ&ELEC
        choisirElec() {
            this.lengthCompteurgaz = 0
            this.num_comp_gaz = []
        },
        choisirGaz() {
            this.lengthCompteurelec = 0
            this.num_comp_elec = []
        },
        // cette fonction vérifie si la sirène était correcte et elle renvoie la liste des sirets associés à cette sirène
        getSiretData() {
            this.$refs.formSiren.validate()
            if (this.$refs.formSiren.validate()) {

                this.dialog = true
                this.loading = true
                this.error = false

                this.siretFound = 0
                this.inseeResponse = null
                this.siretExist = []
                var formData = new FormData()
                formData.append('siren', this.siren)
                formData.append('token', this.getToken('token'))
                axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getListeSiret/',
                    method: 'POST',
                    data: formData,

                })
                    .then((res) => {
                        if (res.data['result'] === 1) {
                            if (res.data['value'] == 0) {
                                this.siretFound = 2
                                this.dialog = true
                                this.error = true
                                this.messageDialoge = "Il y a trop de siret associé a ce siren merci de completer le SIRET manuellement"
                            } else if (res.data['value'] == 1) {
                                if (res.data['data'].length == 0) {
                                    this.siretFound = 0
                                    this.dialog = true
                                    this.error = true
                                    this.messageDialoge = "Votre siren est incorrect, veuillez vérifier vos données"

                                } else {
                                    this.siretFound = 1
                                    var j = 0
                                    for (var i = 0; i < res.data['data'].length; i++) {
                                        this.siretExist.push({ name: res.data['data'][i].adresse, disable: true })
                                        this.siretExist.push({ name: res.data['data'][i].siret, disable: false })
                                    }

                                    this.dialog = false
                                    this.loading = false
                                    this.error = false

                                }
                                //this.siretExist = res.data['data'][0].siret;
                            } else {
                                this.siretFound = 3
                                this.dialog = true
                                this.error = true
                                this.messageDialoge = "Votre siren est incorrect, veuillez vérifier vos données"
                            }
                            this.loading = false
                        } else {
                            this.dialog = false
                            this.loading = false
                            this.error = false
                        }
                    })
                    .catch((err) => {

                        this.dialog = false
                        this.loading = false
                        this.error = false
                        console.log(err)
                    })
            }
        },
        //recherche toutes les données sur une siret choisie
        rechercheParSiret() {
            this.$refs.formSiret.validate()
            if (this.$refs.formSiret.validate()) {
                this.hasGeneratePDF = false;

                this.dialog = true
                this.loading = true
                this.error = false
                var formData = new FormData()
                formData.append('siret', this.siret)
                formData.append('token', this.getToken('token'))
                axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getDonneParSiret/',
                    method: 'POST',
                    data: formData,

                }).then((res) => {
                    if (res.data.result == true) {
                        this.siretInccorect = false
                        this.raison = res.data.raison
                        this.siret = res.data.siret
                        this.siren = res.data.siren
                        this.adressePostale = res.data.adresse
                        this.codeNaf = res.data.codeNaf
                        this.RCS = res.data.rcs
                        if (res.data.infoPerson == true) {
                            this.email = res.data.AdresseEmail
                            this.num_tel = res.data.numTele
                            this.nom = res.data.nom
                            this.prenom = res.data.prenom
                        } else {
                            this.email = ''
                            this.num_tel = ''
                            this.nom = ''
                            this.prenom = ''
                        }
                        this.num_comp_gaz = ['']
                        this.num_comp_elec = ['']
                        this.elec = 'gaz'
                        this.inseeResponse = true
                        this.dialog = false
                        this.loading = false
                        this.error = false
                    } else {
                        this.messageDialoge = "Votre siret est incorrect, veuillez vérifier vos données"
                        this.dialog = true
                        this.loading = false
                        this.error = true
                    }

                }).catch((err) => {
                    this.messageDialoge = "Votre siret est incorrect, veuillez vérifier vos données"
                    this.dialog = true
                    this.loading = false
                    this.error = true
                })
            }

        },
        async verifPageEnd(url, pdfDoc, currentPage, currentY) {
            if (currentY < 250) {
                const pageAdded = await fetch(url).then((res) => res.arrayBuffer());
                const pdfDocLoaded = await PDFDocument.load(page)
                return { pdfDoc: pdfDocLoaded, page: pageAdded, y: 750, isNew: true };
            } else {
                return { pdfDoc: pdfDoc, page: currentPage, y: currentY, isNew: false };
            }
        },
        //la fonction appelle l'une des fonctions précédentes qui génèrent le type de leur pdf sellon
        async generatePdf() {
            this.$refs.formUpload.validate()
            if (this.$refs.formUpload.validate()) {
                this.hasGeneratePDF = true;
                /*if (this.typeContrat === 'gaz') {
                    this.generatePdfGaz()
                } else if (this.typeContrat === 'elec') {
                    this.generatePdfElec()
                }else{
                    this.generatePdfElecAndGaz()
                }*/
                //recherche point enedis


                this.dialog = true
                this.loading = true
                this.error = false

                // convert the html to pdf
                if (this.typeContrat === 'gaz') {
                    try {
                        await this.generateACDGAZ();
                        this.dialog = false
                        this.loading = false
                        this.error = false
                    } catch (error) {
                        this.hasGeneratePDF = false
                        this.dialog = false
                        this.loading = false
                        this.error = false
                    } finally {
                        this.dialog = false;
                        this.loading = false
                    }
                } else if (this.typeContrat === 'elec') {

                    /*formDonne.append('codePostale', this.dataFoundSociete.etablissement.codePostalEtablissement)
                    formDonne.append('typeVoie', this.dataFoundSociete.etablissement.typeVoieEtablissement)
                    formDonne.append('nomVoie', this.dataFoundSociete.etablissement.libelleVoieEtablissement)
                    formDonne.append('codeInsee', this.dataFoundSociete.etablissement.codeCommuneEtablissement)*/
                    var formDonne = new FormData()
                    formDonne.append('numCompteur', this.num_comp_elec)
                    formDonne.append('token', this.getToken('token'))

                    await axios({
                        url: process.env.VUE_APP_URL_API_CLIENT + 'verifCompteurExist/',
                        method: 'POST',
                        data: formDonne,

                    }).then(async (res) => {
                        if (res.data.result === 'permission') {
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                        if (res.data[0].result === 'valide') {
                            this.enedisRecherchePointResult = res.data[0].result;
                            await this.generateACDELEC()
                            this.dialog = false
                            this.loading = false
                            this.error = false
                        } else if (res.data[0].result === 'notValide') {
                            this.hasGeneratePDF = false
                            this.dialog = false
                            this.loading = false
                            this.error = false
                            this.enedisRecherchePointResult = res.data[0].result;
                            this.missedCompteur = res.data[0].compteurError;
                            this.dialog = true
                            this.error = true
                            this.messageDialoge = "le compteur n°" + this.missedCompteur + " n'existe pas"
                        } else if (res.data[0].result === 'dataErreur') {
                            this.hasGeneratePDF = false
                            this.dialog = false
                            this.loading = false
                            this.error = false
                            this.enedisRecherchePointResult = res.data[0].result;
                            this.dialog = true
                            this.error = true
                            this.messageDialoge = "veuillez vérifier vos données."
                        } else {
                            this.hasGeneratePDF = false
                            this.dialog = false
                            this.loading = false
                            this.error = false
                            this.enedisRecherchePointResult = res.data[0].result;
                            this.dialog = true
                            this.error = true
                            this.messageDialoge = "Il semble qu'une erreur soit survenue !"
                        }
                    }).catch((err) => {
                        this.hasGeneratePDF = false
                        this.dialog = false
                        this.loading = false
                        this.error = false
                        console.log(err)
                    })
                } else {
                    var formDonne = new FormData()
                    formDonne.append('numCompteur', this.num_comp_elec)
                    formDonne.append('token', this.getToken('token'))

                    await axios({
                        url: process.env.VUE_APP_URL_API_CLIENT + 'verifCompteurExist/',
                        method: 'POST',
                        data: formDonne,

                    }).then(async (res) => {
                        if (res.data.result === 'permission') {
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                        if (res.data[0].result === 'valide') {
                            this.enedisRecherchePointResult = res.data[0].result;
                            await this.generateACDDUAL()
                            this.dialog = false
                            this.loading = false
                            this.error = false
                        } else if (res.data[0].result === 'notValide') {
                            this.hasGeneratePDF = false
                            this.dialog = false
                            this.loading = false
                            this.error = false
                            this.enedisRecherchePointResult = res.data[0].result;
                            this.missedCompteur = res.data[0].compteurError;
                            this.dialog = true
                            this.error = true
                            this.messageDialoge = "le compteur n°" + this.missedCompteur + " n'existe pas"
                        } else if (res.data[0].result === 'dataErreur') {
                            this.hasGeneratePDF = false
                            this.dialog = false
                            this.loading = false
                            this.error = false
                            this.enedisRecherchePointResult = res.data[0].result;
                            this.dialog = true
                            this.error = true
                            this.messageDialoge = "veuillez vérifier vos données comme l'adresse (ou) code insee."
                        } else {
                            this.hasGeneratePDF = false
                            this.dialog = false
                            this.loading = false
                            this.error = false
                            this.enedisRecherchePointResult = res.data[0].result;
                            this.dialog = true
                            this.error = true
                            this.messageDialoge = "Il semble qu'une erreur soit survenue !"
                        }
                    }).catch((err) => {
                        this.hasGeneratePDF = false
                        this.dialog = false
                        this.loading = false
                        this.error = false
                        console.log(err)
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            }
        },
        async generateACDELEC() {
            try {
                let mergedPdf = await PDFDocument.create();

                // ------------ photo uploaded AMO pour la réalisation des installations ------------
                const page1Doc = await this.page1('/pdf/acd/ACDGreenEco-p1.pdf');
                mergedPdf = await this.addPagesToPdf(mergedPdf, page1Doc);


                // ------------ page 2 - compteurs ------------

                const page2Doc = await this.page2ELEC(
                    "/pdf/acd/ACDGreenEco-p2.pdf", mergedPdf);
                mergedPdf = await this.addPagesToPdf(mergedPdf, page2Doc);


                // ******************** save pdf ******************** 
                const filledPdfBytes = await mergedPdf.save();

                // ******************** Download the filled PDF ******************** 
                const blob = new Blob([filledPdfBytes], { type: "application/pdf" });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = `ACD-${this.siret}.pdf`;
                link.click();
            } catch (error) {
                console.log(error)
            }
        },
       
        async generateACDGAZ() {
            try {
                let mergedPdf = await PDFDocument.create();
                // ------------ photo uploaded AMO pour la réalisation des installations ------------
                const page1Doc = await this.page1('/pdf/acd/ACDGreenEco-p1.pdf');
                mergedPdf = await this.addPagesToPdf(mergedPdf, page1Doc);


                // ------------ page 2 - compteurs ------------

                const page2Doc = await this.page2GAZ("/pdf/acd/ACDGreenEco-p2.pdf", mergedPdf);
                mergedPdf = await this.addPagesToPdf(mergedPdf, page2Doc);


                // ******************** save pdf ******************** 
                const filledPdfBytes = await mergedPdf.save();

                // ******************** Download the filled PDF ******************** 
                const blob = new Blob([filledPdfBytes], { type: "application/pdf" });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = `ACD-${this.siret}.pdf`;
                link.click();
                
            } catch (error) {
                console.log(error)
            }

          
        },
        async generateACDDUAL() {
            try {
                await this.generateACDELEC();
                await this.generateACDGAZ();
            } catch (error) {
                this.hasGeneratePDF = false
                this.dialog = false
                this.loading = false
                this.error = false
            } finally {
                this.dialog = false
                this.loading = false;
            }
        },
        async page1(pdfPath) {
            const {
                pdfDoc,
                pages,
                page,
                pageSize,
                fontFamily,
                fontFamilyItalic,
                fontFamilyBold,
                fontFamilyBoldItalic,
                xCenterPosition,
                yCenterPosition,
            } = await this.init(pdfPath);

            const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman); // Embedding font
            const HelveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica); // Embedding font
            const fontSize = 10;
            const textParaColor = [0, 0, 0];

            var raison = this.raison
            var nom = this.nom
            var prenom = this.prenom
            var email = this.email
            var fonction = this.fonction
            var tel = this.num_tel
            var adresse = this.adressePostale
            var siret = this.siret
            var codeNaf = this.codeNaf
            const textList = [
                "Par la présente, afin de réaliser une étude comparative des offres d’énergie,",
                `je soussigné(e) ${prenom} / ${nom} / ${fonction} (Prénom / NOM / fonction)`,
                `Tél / mail: ${tel} / ${email}`,
            ]
            let startX = 70;
            let startingY = 590;
            for (let i = 0; i < textList.length; i++) {
                const lineText = textList[i];
                page.drawText(lineText, {
                    x: startX,
                    y: startingY,
                    size: fontSize,
                    font: HelveticaFont,
                    color: rgb(textParaColor[0],textParaColor[1],textParaColor[2]),
                });
                startingY = startingY - 22;
            }

            let newY = await this.drawMultiLineText({
                page: page, 
                text: `Adresse professionnelle (si différente du siège):`, 
                x: startX, 
                y: startingY, 
                font: HelveticaFont, 
                size: fontSize, 
                textColor: textParaColor, 
                lineHeight: 14,
                width: page.getSize().width - (startX * 2),  
            });
            startingY = newY - 14;
            newY = await this.drawMultiLineText({
                page: page, 
                text: `représentant la société: ${raison} (Raison sociale et forme juridique)`, 
                x: startX, 
                y: startingY, 
                font: HelveticaFont, 
                size: fontSize, 
                textColor: textParaColor, 
                lineHeight: 14,
                width: page.getSize().width - (startX * 2),  
            });
            startingY = newY - 14;
            newY = await this.drawMultiLineText({
                page: page, 
                text: `dont le siège se situe: ${adresse}`, 
                x: startX, 
                y: startingY, 
                font: HelveticaFont, 
                size: fontSize, 
                textColor: textParaColor, 
                lineHeight: 14,
                width: page.getSize().width - (startX * 2),  
            });
            startingY = newY - 14;
            newY = await this.drawMultiLineText({
                page: page, 
                text: `n°SIRET: ${siret}, code NAF: ${codeNaf},`, 
                x: startX, 
                y: startingY, 
                font: HelveticaFont, 
                size: fontSize, 
                textColor: textParaColor, 
                lineHeight: 22,
                width: page.getSize().width - (startX * 2),  
            });
            startingY = newY - 14;

            const textList2 = [
                `autorise GREEN ECO ENERGY, ses fournisseurs partenaires ainsi que ses agents commerciaux à`,
                `demander des offres de fourniture d’énergie pour la consommation des sites de mon entreprise.`,
            ]
            for (let i = 0; i < textList2.length; i++) {
                const lineText = textList2[i];
                page.drawText(lineText, {
                    x: startX,
                    y: startingY,
                    size: fontSize,
                    font: HelveticaFont,
                    color: rgb(textParaColor[0],textParaColor[1],textParaColor[2]),
                });
                startingY = startingY - 14;
            }
            startingY = startingY - 6;
            const textList3 = [
                `Je les autorise également à demander aux gestionnaires de réseau les données techniques et`,
                `contractuelles, la puissance maximale quotidienne en kVA ou kWh et l’historique des consommations`,
                `des sites.`,
            ]
            for (let i = 0; i < textList3.length; i++) {
                const lineText = textList3[i];
                page.drawText(lineText, {
                    x: startX,
                    y: startingY,
                    size: fontSize,
                    font: HelveticaFont,
                    color: rgb(textParaColor[0],textParaColor[1],textParaColor[2]),
                });
                startingY = startingY - 14;
            }
            startingY = startingY - 6;
            const textList4 = [
                `Cette autorisation est valable pour une durée de 12 mois.`,
            ]
            for (let i = 0; i < textList4.length; i++) {
                const lineText = textList4[i];
                page.drawText(lineText, {
                    x: startX,
                    y: startingY,
                    size: fontSize,
                    font: HelveticaFont,
                    color: rgb(textParaColor[0],textParaColor[1],textParaColor[2]),
                });
                startingY = startingY - 14;
            }
            startingY = startingY - 6;
            const textList5 = [
                `Le présent document ne peut en aucun cas être considéré comme un engagement de signer un`,
                `contrat d’énergie par l’intermédiaire de GREEN ECO ENERGY.`,
            ]
            for (let i = 0; i < textList5.length; i++) {
                const lineText = textList5[i];
                page.drawText(lineText, {
                    x: startX,
                    y: startingY,
                    size: fontSize,
                    font: HelveticaFont,
                    color: rgb(textParaColor[0],textParaColor[1],textParaColor[2]),
                });
                startingY = startingY - 14;
            }

            // ------------------ Date --------------
            const dateTextX = 100; // Static X position
            const dateTextY = 158; // Static Y position
            await this.drawAlignedText({
                page: page,
                x: dateTextX,
                y: dateTextY,
                align: 'center',
                text: this.getDate(), 
                font: timesRomanFont,
                size: fontSize,
                textColor: [0, 0, 0],
                width: 100,
                height: 22,

            });

            return pdfDoc;
        },
        async page2ELEC(pdfPath, mergedPdf) {
            let {
                pdfDoc: pdfDocLocal,
                pages,
                page: pageLocal,
                pageSize,
                fontFamily,
                fontFamilyItalic,
                fontFamilyBold,
                fontFamilyBoldItalic,
                xCenterPosition,
                yCenterPosition,
            } = await this.init(pdfPath);

            const { width, height } = pageLocal.getSize()
            const timesRomanFont = await pdfDocLocal.embedFont(StandardFonts.HelveticaBold)
            const timesRomanFontNormal = await pdfDocLocal.embedFont(StandardFonts.Helvetica)

            var startingPositon = 725
            const leftRightPadding = width * 0.05;
            const availableWidth = width - (2 * leftRightPadding);
            const cellsWidths = [0.19, 0.51, 0.30]
            startingPositon -= 60
            // Draw main header
            startingPositon = this.drawMainHeader(pageLocal, startingPositon, leftRightPadding, availableWidth, timesRomanFont,"Liste des sites d'électricité raccordés au RPD");

            //add date a
            await this.drawAlignedText({
                page: pageLocal,
                x: 100,
                y: 136,
                align: 'center',
                text: this.getDate(),
                font: timesRomanFontNormal,
                size: 12,
                textColor: [0, 0, 0],
                width: 100,
                height: 22,

            });

            // Draw column headers
            startingPositon = this.drawColumnHeaders(pageLocal, startingPositon, leftRightPadding, availableWidth, cellsWidths, timesRomanFont);
            for (let i = 0; i < this.num_comp_elec.length; i++) {
                let startingXposition = leftRightPadding;

                for (let j = 0; j < 3; j++) {

                    await this.drawAlignedText({
                        page: pageLocal,
                        x: startingXposition,
                        y: startingPositon,
                        align: 'center',
                        text: j==0?this.num_comp_elec[i]:"-",
                        font: timesRomanFontNormal,
                        size: 12,
                        textColor: [0, 0, 0],
                        width: availableWidth * cellsWidths[j],
                        height: 22,
                        borderWidth: 1,
                        borderColor: [0, 0, 0]
                    });
                    startingXposition += availableWidth * cellsWidths[j];
                }
                startingPositon = startingPositon - 22;

                if (startingPositon < 250) {
                    mergedPdf = await this.addPagesToPdf(mergedPdf, pdfDocLocal);
                    const newPDFRes = await this.init(pdfPath);
                    pdfDocLocal = newPDFRes.pdfDoc;
                    pageLocal = newPDFRes.page;
                    startingPositon = 725 - 60;

                    await this.drawAlignedText({
                        page: pageLocal,
                        x: 100,
                        y: 136,
                        align: 'center',
                        text: this.getDate(),
                        font: timesRomanFontNormal,
                        size: 12,
                        textColor: [0, 0, 0],
                        width: 100,
                        height: 22,

                    });
                }
            }


            return pdfDocLocal;
        },
        async page2GAZ(pdfPath, mergedPdf) {
            let {
                pdfDoc: pdfDocLocal,
                pages,
                page: pageLocal,
                pageSize,
                fontFamily,
                fontFamilyItalic,
                fontFamilyBold,
                fontFamilyBoldItalic,
                xCenterPosition,
                yCenterPosition,
            } = await this.init(pdfPath);

            const { width, height } = pageLocal.getSize()
            const timesRomanFont = await pdfDocLocal.embedFont(StandardFonts.HelveticaBold)
            const timesRomanFontNormal = await pdfDocLocal.embedFont(StandardFonts.Helvetica)

            var startingPositon = 725
            const leftRightPadding = width * 0.05;
            const availableWidth = width - (2 * leftRightPadding);
            const cellsWidths = [0.19, 0.51, 0.30]
            startingPositon -= 60
            // Draw main header
            startingPositon = this.drawMainHeader(pageLocal, startingPositon, leftRightPadding, availableWidth, timesRomanFont,"Liste des sites Gaz raccordés au RPD");

            //add date a
            await this.drawAlignedText({
                page: pageLocal,
                x: 100,
                y: 136,
                align: 'center',
                text: this.getDate(),
                font: timesRomanFontNormal,
                size: 12,
                textColor: [0, 0, 0],
                width: 100,
                height: 22,

            });

            // Draw column headers
            startingPositon = this.drawColumnHeaders(pageLocal, startingPositon, leftRightPadding, availableWidth, cellsWidths, timesRomanFont);
            for (let i = 0; i < this.num_comp_gaz.length; i++) {
                let startingXposition = leftRightPadding;

                for (let j = 0; j < 3; j++) {

                    await this.drawAlignedText({
                        page: pageLocal,
                        x: startingXposition,
                        y: startingPositon,
                        align: 'center',
                        text: j==0?this.num_comp_gaz[i]:"-",
                        font: timesRomanFontNormal,
                        size: 12,
                        textColor: [0, 0, 0],
                        width: availableWidth * cellsWidths[j],
                        height: 22,
                        borderWidth: 1,
                        borderColor: [0, 0, 0]
                    });
                    startingXposition += availableWidth * cellsWidths[j];
                }
                startingPositon = startingPositon - 22;

                if (startingPositon < 250) {
                    mergedPdf = await this.addPagesToPdf(mergedPdf, pdfDocLocal);
                    const newPDFRes = await this.init(pdfPath);
                    pdfDocLocal = newPDFRes.pdfDoc;
                    pageLocal = newPDFRes.page;
                    startingPositon = 725 - 60;

                    await this.drawAlignedText({
                        page: pageLocal,
                        x: 100,
                        y: 136,
                        align: 'center',
                        text: this.getDate(),
                        font: timesRomanFontNormal,
                        size: 12,
                        textColor: [0, 0, 0],
                        width: 100,
                        height: 22,

                    });
                }
            }


            return pdfDocLocal;
        },
       
        async urltoFile(url, filename, mimeType) {
            return (fetch(url)
                .then(function (res) { return res.arrayBuffer(); })
                .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
            );
        },
        //envoyer le document et reinitiliser tout les valeur
        enregistrer() {

            this.dialog = true
            this.loading = true
            this.error = false

            //upload pdf file to php server le form data remplire dans les function generate pdf...
            axios({
                url: process.env.VUE_APP_URL_API_CLIENT + 'autorisationDeCollebctionDeDonner/',
                method: 'POST',
                data: this.formData,

            }).then((res) => {
                if (res.data.result == false) {
                    this.examine_file = false
                    this.dialog = false
                    this.loading = false
                    this.error = false
                    this.dialog = true
                    this.loading = false
                    this.error = true
                    this.messageDialoge = "Une erreur s'est produite veuillez réessayer"
                } else if (res.data.result == true) {

                    this.dialog = false
                    this.loading = false
                    this.error = false
                    this.dialog = true
                    this.loading = false
                    this.error = false
                    this.messageDialoge = "Cette demande a été enregistrée avec succès"
                    this.siretFound = 0
                    this.isSend = 0
                    this.inseeResponse = false
                    this.siren = null
                    this.nom = null
                    this.prenom = null
                    this.raison = null
                    this.email = null
                    this.num_tel = null
                    this.elec = null
                    this.num_comp_gaz = []
                    this.lengthCompteurgaz = 0
                    this.lengthCompteurelec = 0
                    this.num_comp_elec = []
                } else if (res.data.result === 'permission') {
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser')
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                    //access denied
                } else {
                    this.examine_file = false

                    this.dialog = false
                    this.loading = false
                    this.error = false
                    this.dialog = true
                    this.loading = false
                    this.error = true
                    this.messageDialoge = "Une erreur s'est produite veuillez réessayer"
                }
            }).catch((err) => {
                console.log(err)
                this.examine_file = false

                this.dialog = false
                this.loading = false
                this.error = false
                this.dialog = true
                this.loading = false
                this.error = true
                this.messageDialoge = "Une erreur s'est produite veuillez réessayer"
            })
        },
        // fonction qui crée la date du jour pour la mettre à la fin du fichier pdf "fait le"
        currentDateTime() {
            const current = new Date()
            const date =
                current.getDate() +
                '/' +
                (current.getMonth() + 1) +
                '/' +
                current.getFullYear()
            return date
        },
        async drawAlignedText({ page, text, x = 0, y = 0, font, size, align, textColor = [0, 0, 0], width = 0, height = 0, borderWidth = 0, borderColor,backgroundColor,offsetText = 0 }) {
            const widthText = font.widthOfTextAtSize(text, size);
            const heightText = font.heightAtSize(size) - 2;
            // calculate center position
            let xPosition = x + width / 2;
            if (align == 'center') xPosition = xPosition - widthText / 2;
            if (align == 'left') xPosition = x;
            // draw rectangle
            if (width > 0 && borderWidth > 0) {
                let rectProps = {
                    x: x,
                    y: y,
                    width: width,
                    height: height > 0 ? height : heightText,
                    borderWidth: borderWidth,
                    color: backgroundColor ? rgb(backgroundColor[0], backgroundColor[1], backgroundColor[2]) : undefined,
                }
                if (borderColor) rectProps.borderColor = rgb(borderColor[0], borderColor[1], borderColor[2]);
                page.drawRectangle(rectProps);
            }
            if (height > 0) y = y + (height / 2) - (heightText / 2)
            // draw text
            page.drawText(text, {
                x: xPosition+offsetText,
                y: y,
                size: size,
                font: font,
                color: rgb(textColor[0], textColor[1], textColor[2]),
            });
        },
        async drawMultiLineText({page, text, x= 0, y= 0, font, size, lineHeight, align, textColor= [0, 0, 0], width=0}){
            const lineSpace = lineHeight || size * 1.2; // Line height is often 120% of font size
            const heightText = font.heightAtSize(size) - (size * 0.2);
            // Prepare for multi-line text layout
            const multiText = layoutMultilineText(text, {
                alignment: align || 'left',
                fontSize: size,
                font: font,
                lineHeight: lineSpace,
                bounds: { width: width, height: 1000 }
            });

            // Draw each line of text
            let startingPosition = y;
            for (let i = 0; i < multiText.lines.length; i++) {
                const line = multiText.lines[i];
                page.drawText(line.text, {
                    x: x,
                    y: startingPosition,
                    size: size,
                    font: font,
                    lineHeight: lineSpace,
                    maxWidth: width,
                    color: rgb(textColor[0], textColor[1], textColor[2]),
                });
                // Move down for to the next line when its not the last line
                if(i < multiText.lines.length - 1){
                    startingPosition = startingPosition - lineSpace;
                }
            }

            return startingPosition - heightText;
        },
        async addPagesToPdf(mergedPdf, donorPdf) {
            const copiedPages = await mergedPdf.copyPages(
                donorPdf,
                donorPdf.getPageIndices()
            )
            copiedPages.forEach((page) => {
                mergedPdf.addPage(page)
            })
            return mergedPdf
        }, 
        drawMainHeader(page, yPosition, leftPadding, availableWidth, font,text) {
            this.drawAlignedText({
                page: page,
                x: leftPadding,
                y: yPosition,
                align: 'left',
                text: text,
                font: font,
                size: 10,
                textColor: [1, 1, 1],
                width: availableWidth,
                height: 22,
                borderWidth: 1,
                borderColor: [0, 0, 0],
                backgroundColor:[0.64,0.64,0.64],
                offsetText:10
            });
            return yPosition - 22; // Return new Y position after drawing header
        },

        drawColumnHeaders(page, yPosition, leftPadding, availableWidth, cellsWidths, font) {
            const headers = ["Nom Du Site", "Adresse", "REFERENCE du site d'électricité"];
            let xPosition = leftPadding;

            headers.forEach((header, index) => {

                this.drawAlignedText({
                    page: page,
                    x: xPosition,
                    y: yPosition,
                    align: 'center',
                    text: header,
                    font: font,
                    size: 10,
                    textColor: [0, 0, 0],
                    width: availableWidth * cellsWidths[index],
                    height: 22,
                    borderWidth: 1,
                    borderColor: [0, 0, 0]
                });
                xPosition += availableWidth * cellsWidths[index];
            });

            return yPosition - 22; // Return new Y position after drawing column headers
        },
        // initializing pdf loading and global variables
        async init(pdfPath) {
            const response = await fetch(pdfPath);
            const arrayBuffer = await response.arrayBuffer();
            const pdfDoc = await PDFDocument.load(arrayBuffer);

            const pages = pdfDoc.getPages();
            const page = pages[0];
            const pageSize = page.getSize();

            const fontFamily = await pdfDoc.embedFont(StandardFonts.Helvetica);
            const fontFamilyItalic = await pdfDoc.embedFont(StandardFonts.HelveticaOblique);
            const fontFamilyBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
            const fontFamilyBoldItalic = await pdfDoc.embedFont(StandardFonts.HelveticaBoldOblique);

            const xCenterPosition = pageSize.width / 2;
            const yCenterPosition = pageSize.height / 2;
            const xCenterPositionWithGap = xCenterPosition + 40;

            const xStartLeft = 113;

            return {
                pdfDoc,
                pages,
                page,
                pageSize,
                fontFamily,
                fontFamilyItalic,
                fontFamilyBold,
                fontFamilyBoldItalic,
                xCenterPosition,
                yCenterPosition,
                xCenterPositionWithGap,
                xStartLeft,
            }
        },
        getDate() {
            const today = new Date();
            const day = String(today.getDate()).padStart(2, '0');
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const year = today.getFullYear();

            return `${day}/${month}/${year}`;
        }

    }
}

</script>
<style scoped>
::v-deep .v-label {
    margin: 0px;
}
</style>
<style scoped>
@media only screen (max-width:350px) {
    ::v-deep .v-application .ml-2 {
        margin-left: 0px !important;
    }
}
</style>
<style>
@media only screen and (max-width:400px) {
    .title {
        font-size: small,
    }
}
</style>
